/**
 *
 *
 * @export
 * @param {string} url
 * @param {*} [params={}]
 * @returns url as a string
 */
export default function swrUrlParser(url: string, params = {}){
	const urlParams = new URLSearchParams(params).toString();
    return `${url}?${urlParams}`;
}