import { captureException, captureMessage } from '@sentry/node';
import swrUrlParser from '../swrUrlParser/swrUrlParser';

const handleErrorResponse = (url, error) => {
  let errorResponse;

  const errorCode = error?.response?.status;
  // Check for the error code from the error object.
  if (errorCode) {
    switch (errorCode) {
      case 400: {
        errorResponse = '400 Bad Request';
        break;
      }
      case 401: {
        errorResponse = '401 Unauthorized';
        break;
      }
      case 403: {
        errorResponse = '403 Forbidden';
        break;
      }
      case 404: {
        errorResponse = '404 Not Found';
        break;
      }
      case 405: {
        errorResponse = '405 Method Not Allowed';
        break;
      }
      case 500: {
        errorResponse = '500 Internal Server Error';
        break;
      }
      default: {
        errorResponse = error;
        break;
      }
    }
  }
  // Pass an Error object to captureException() to get it captured as event.
  captureException(error);
  // Pass an Error message to captureMessage() to capture a bare message.
  captureMessage(
    `Call to ${url} returned ${errorResponse}, status: ${error.response?.statusText}`
  );
};

async function getFetch(url, params = {}, cache = 3600, BTSESSION?: string) {
  let response;

  const fullUrl = swrUrlParser(url, params);
 
  let cacheTime;
  // Turn off cache for test01 debugging
  if (url.includes('test01')) {
    cacheTime = 0;
  } else {
    cacheTime = cache;
  }

  if (BTSESSION && typeof BTSESSION != 'undefined') {
    try {
      response = await fetch(fullUrl, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Cookie: 'BTSESSION=' + BTSESSION,
          'X-BrightTALK-access': 'niHeeF1ao2',
          'BrightTALK-API-Client': 'audience-portal-wordpress',
          'Content-Type': 'application/json',
        },
        next: { revalidate: cacheTime },
      });
      response = await response.json();
    } catch (err) {
      handleErrorResponse(fullUrl, err);
    }
  } else {
    try {
      response = await fetch(fullUrl, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-BrightTALK-access': 'niHeeF1ao2',
          'BrightTALK-API-Client': 'audience-portal-wordpress',
          'Content-Type': 'application/json',
        },
        next: { revalidate: cacheTime },
      });
      response = await response.json();
    } catch (err) {
      handleErrorResponse(fullUrl, err);
    }
  }

  return response;
}

export default getFetch;
