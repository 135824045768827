import React, { FC } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { Container, Row } from 'reactstrap';

interface LoadMoreProps {
  loader: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  showLoadMore: boolean;
  bddLoadmoreBlock?: string
  gridItemName?: string
}

const LoadMore: FC<LoadMoreProps> = ({
  onClick,
  loader = false,
  showLoadMore,
  bddLoadmoreBlock,
  gridItemName
}) => {
  return (
    <>
      {showLoadMore ? (

        <Row className={classnames(styles[gridItemName + '-loadmore'], styles['loadmore-container'])}>
          <div className={styles['loadmore-line']}>
            <button
              onClick={onClick}
              className={styles['loadmore-btn-container']}
              onSubmit={loader['onSubmit']}
              data-bdd={ bddLoadmoreBlock }
            >
              <span className={styles['loadmore-icon-container']}>
              <span className={styles['load-icon-bg']}>
                {loader ? (
                  <FontAwesomeIcon className={classnames(styles['bt-icons'], styles['loading-spinner'] ) } icon={faSpinner} spin />
                ) : (
                  <FontAwesomeIcon className={styles['bt-icons']} icon={faAngleDown} />
                )}
              </span>
              </span>
              <span className="sr-only">Load more</span>
            </button>
          </div>
        </Row>

      ) : (
        ''
      )}
    </>
  );
};

export default LoadMore;
