import React, { FC } from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import styles from './index.module.scss';

export interface SectionHeadingsProps {
  title: string;
  description?: string;
  link?: string;
  linkTitle?: string;
  headingBlockName?: string;
  dataBddTitle?: string;
  dataBddDesc?: string;
  dataBddLink?: string;
  linkNewTab?: boolean;
  classes?: string;
}

const SectionHeadings: FC<SectionHeadingsProps> = ({
  title,
  description,
  link,
  linkTitle,
  headingBlockName,
  dataBddTitle,
  dataBddDesc,
  dataBddLink,
  linkNewTab,
  classes
}) => {
  return (
    <div
      className={classnames(
        styles['bt-section-headings'],
        styles[classes],
        styles[headingBlockName],
        'generic-section-heading'
      )}
      data-bdd={headingBlockName}
    >
      <h2 data-bdd={dataBddTitle}>{title}</h2>
      { description && link && linkTitle ? (
        <p data-bdd={dataBddDesc}>
          {description}
          {link != '' && (
            <>
              {link === '/all-talks/' ? (
                <a
                  href={link}
                  className={styles['bt-section-headings-link']}
                  data-bdd={dataBddLink}
                >
                  {linkTitle}
                  <FontAwesomeIcon
                    className={styles['bt-section-headings-icon']}
                    icon={faAngleRight}
                  ></FontAwesomeIcon>
                </a>
              ) : (
                <a className={styles['bt-section-headings-link']} href={link} data-bdd={dataBddLink} target={(linkNewTab) ? '_blank' : ''}>
                    {linkTitle}
                    <FontAwesomeIcon
                      className={styles['bt-section-headings-icon']}
                      icon={faAngleRight}
                    ></FontAwesomeIcon>
                </a>
              )}
            </>
          )}
        </p>
      ) : (
        ''
      )}
    </div>
  );
};

export default SectionHeadings;
