import React, { FC } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

interface MetaHeadProps {
  title?: string;
  description?: string;
  pageName?: string;
  robots?: string;
  imageUrl?: string;
  hostUrl: string;
  staticAssetUrl: string;
}

const MetaHead: FC<MetaHeadProps> = ({
  title = 'Videos and webinars from business experts - BrightTALK',
  description = 'Free online events featuring the latest in B2B solution thinking from world-class visionaries, experts and innovators.',
  pageName,
  robots = 'index,follow',
  imageUrl = '',
  hostUrl,
  staticAssetUrl,
}) => {
  const router = useRouter();
  const defaultHostUrl =
    hostUrl !== '' ? hostUrl : 'https://www.brighttalk.com';
  const canonicalUrl = defaultHostUrl + router?.pathname;
  const defaultStaticAssetUrl =
    staticAssetUrl !== '' ? staticAssetUrl : 'https://assets.brighttalk.com';
  if (imageUrl == '') {
    imageUrl = hostUrl + '/portal/bt-tt-fb-logo.png';
  }

  return (
    <Head>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="robots" content={robots}></meta>
      <meta httpEquiv="Expires" content="Wed, 11 Jan 1984 05:00:00 GMT" />
      <meta
        httpEquiv="Cache-Control"
        content="no-cache, must-revalidate, max-age=0"
      />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={title} />
      <meta
        name="og:description"
        property="og:description"
        content={description}
      />
      <meta name="og:image" property="og:image" content={imageUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:site_name" content="BrightTALK" />
      <meta property="og:url" content={canonicalUrl} />
      <meta name="brighttalk-page-architecture" content="react" />
      <meta name="brighttalk-page-name" content={pageName} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="BrightTALK" />
      <meta name="twitter:creator" content="" />
      <link
        rel="icon"
        type="image/png"
        href={`${defaultStaticAssetUrl}/images/favicon.ico`}
      />
      <link
        rel="apple-touch-icon"
        href={`${defaultStaticAssetUrl}/images/favicon.ico`}
      />
      <link rel="canonical" href={canonicalUrl} />
      {/* <link rel='preload' href={`${defaultStaticAssetUrl}/fonts/Roboto-Light.woff`} as='font' type='font/woff' crossOrigin='anonymous' ></link>
        <link rel='preload' href={`${defaultStaticAssetUrl}/fonts/Roboto-Regular.woff`} as='font' type='font/woff' crossOrigin='anonymous' ></link>
        <link rel='preload' href={`${defaultStaticAssetUrl}/fonts/Roboto-Bold.woff`} as='font' type='font/woff' crossOrigin='anonymous' ></link>
        <link rel='preload' href={`${defaultStaticAssetUrl}/fonts/Roboto-Medium.woff`} as='font' type='font/woff' crossOrigin='anonymous' ></link>
		 */}
    </Head>
  );
};
export default MetaHead;
