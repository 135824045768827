import React, { FC } from 'react';
import Image from 'next/image';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-thin-svg-icons';
import classnames from 'classnames';

interface FallbackImageProps {
  title?: string;
  gridType?: 'webcasts' | 'summits' | 'series' | 'channels';
  imageType?: 'logo' | 'full';
  pageType?: string; // need to be able to check what page i.e search so we can build new image params
  height?: number;
  width?: number;
  priorityStatus?: boolean;

}

const CardsFallBackImage: FC<FallbackImageProps> = ({ title,  gridType, imageType, width, height, pageType, priorityStatus })  => {

  let gridTypeImage;
  let layoutFill;
  let imageWidth;
  let imageHeight;
  let imageSrc;

  if ( ( gridType === 'summits' || gridType === 'series' ) && pageType != 'search-page' ) {
    gridTypeImage = 'item-image-summits';
    layoutFill = '';
    imageWidth = '1400';
    imageHeight = '200';
    imageSrc = '/portal/placeholders/diamond-cube-svg16-9.svg';

    } else if( pageType === 'search-page'){
    //search page grids have different image sizes depending on whether they are
    //horizontal layout or single column card ( mobile)
    gridTypeImage = 'item-image-searchpage';
    layoutFill = '';
    imageWidth = '640';
    imageHeight = '360';
    imageSrc = '/portal/placeholders/diamond-cube.svg';

    if ( ( gridType === 'summits' ) || ( gridType === 'series' ) ) {
     imageSrc = '/portal/placeholders/diamond-cube-svg16-9.svg';
    }

  } else {
    gridTypeImage = 'item-image-webcasts';
    layoutFill = '';
    imageWidth = '640';
    imageHeight = '360';
    imageSrc = '/portal/placeholders/diamond-cube.svg';
  }

  if (imageType === 'logo') {
    imageWidth  = width;
    imageHeight = height;

     if ( gridType === 'channels') {
      imageSrc = '/portal/placeholders/brand-logo.svg';
     } else {
      imageSrc = '/portal/placeholders/diamond-cube.svg';
     }

  }

  return (

        <Image
          className={classnames( styles[pageType], styles[gridType], styles[gridTypeImage], styles['card-image-el'], styles['placeholder'], styles[imageType] )}
          src={imageSrc}
          alt={title}
          //object-fit="contain"
          blurDataURL={imageSrc}
          width={imageWidth}
          height={imageHeight}
          sizes="(max-width: 992px) 100vw, (max-width: 1400px) 50vw, 100vw"
          placeholder="blur"
          priority={priorityStatus}
        />

  );
};

export default CardsFallBackImage;
