import React, {FC} from 'react';
import {Row, Col} from 'reactstrap';
import classnames from 'classnames';
import styles from './SkeletonSectionHeadings.module.scss';

interface skeletonHeadingProps {
  skeletonHeadingDescription: boolean;
  headingBlockName?: string;
  classes?: string;
}

const SkeletonSectionHeadings: FC<skeletonHeadingProps> = ({
  skeletonHeadingDescription,
  headingBlockName,
  classes,
}) => {

 return (

  <div className={classnames(
    styles['skeleton-loading'],
    styles['bt-section-headings'],
    styles['section-headings'],
    styles[headingBlockName],
    styles[classes],
    'generic-section-heading'
   )}
   data-bdd={headingBlockName}
  >
    <h2 className={styles['section-title']} data-bdd={'Skeleton-section-heading'}></h2>

    { skeletonHeadingDescription == true && (
    <p className={styles['section-heading-description']}></p>
    ) }

  </div>

 );


};
export default SkeletonSectionHeadings;
